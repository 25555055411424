<template>
  <div class="mx-auto" style="width:868px">
    <div class="text-h5 mb-3">Configuring Your Workspace</div>
    <p>
      In order to get the most out of your workspace, there are several pieces of configuration that you need to perform. Let's take a look at each one in detail.
    </p>
    <ul class="pt-1" style="list-style:none">
      <li><a href="#user-settings">Configuring your user profile</a></li>
      <li><a href="#company-profile">Setting Up Your Company Profile</a></li>
      <li><a href="#configuring-branding">Configuring Branding</a></li>
      <li><a href="#adding-to-catalog">Adding Products and Services to the Catalog</a></li>
    </ul>
    <div class="text-h6 mb-3 mt-3" id="user-settings">
      <a href="#user-settings" class="text-decoration-none text-right text-md-left"> # </a>
      User Settings
    </div>
    <p class="pt-3">The user settings area is where you configure settings related to your user account.</p>
    <div class="text-subtitle-1 font-weight-bold">Profile</div>
    <p class="pt-3">
      Your profile contains basic information about you, such as your name, phone and email address. These are used throughout the system, both internally
      (such as for email notifications) and externally (on estimates and invoices you send to your customers).
    </p>
    <v-alert text dense type="warning" border="left"><strong>Important:</strong> Your email address is what you use to log into PriceTable. If you change it on
    your profile to a different one, you will need to use that when logging in from there on.</v-alert>
    <p>Your profile is also where you can upload an avatar picture for your account. Avatars are displayed only to other users on your workspace, but can 
      be useful for easily distinguishing your account from others. Users without avatars are displayed with their first and last initials in the system.
    </p>
    <div class="text-h6 mb-3 mt-3" id="company-profile">
      <a href="#company-profile" class="text-decoration-none text-right text-md-left"> # </a>
      Company Profile
    </div>
    <p class="pt-3">
      The company profile page is where you can put in information about your company, such as its name, contact details and website. You should make sure to 
      put in the customer service phone and email in this section. In addition, uploading a logo can help give your customer interactions a more professional and branded feel.
    </p>
    <p>Lastly, if you operate in an industry that requires business licenses, filling in the licensure information would be a good idea. PriceTable automatically appends it to 
      all customer-facing documents and pages, such as estimates and invoices.
    </p>
    <div class="text-h6 mb-3 mt-3" id="configuring-branding">
      <a href="#configuring-branding" class="text-decoration-none text-right text-md-left"> # </a>
      Branding
    </div>
    <p class="pt-3">
      PriceTable allows you to reflect your company's brand colors on customer-facing pages and documents. This is very useful for reinforcing the impression that when a 
      customer receives an estimate or an invoice, it is coming from you.
    </p>
    <p>Branding has five components:</p>
    <ul class="pt-1" style="list-style:none">
      <li>Primary color: Affects toolbar background colors on documents and pages.</li>
      <li>Secondary color: Affects background color on documents.</li>
      <li>Backdrop color: Affects the page backdrop behind documents.</li>
      <li>Field Title Text Color: Affects text color on field labels and document titles.</li>
      <li>Field Value Text Color: Affects text color on field values.</li>
    </ul>
    <p class="pt-3">
      You can use one or more of these settings to give your customer-facing documents the look-and-feel of your company's materials. Coming up with a professional and pleasing 
      combination can take some experimentation, so make sure to use the Preview button liberally. If you want to revert to defaults, click the "Restore Defaults" button.
    </p>
    <div class="text-h6 mb-3 mt-3" id="adding-to-catalog">
      <a href="#adding-to-catalog" class="text-decoration-none text-right text-md-left"> # </a>
      Adding Products and Services to the Catalog
    </div>
    <p class="pt-3">
      One of the most important factors for ensuring that you and your team members can generate estimate and invoices quickly is to make sure the quoted items come from a central
      catalog. This is why it would be a good idea to add at least some of the products and services commonly sold by your organization into the catalog.
    </p>
    <p>If you already have a pricing list, you can import it. To do so, download a sample CSV file using the link in the bulk import dialog, then structure your price list to be 
      identical. Then you can upload it as a CSV file and PriceTable will automatically add each row as a catalog item.</p>
  </div>
</template>

<script>
export default {
  created() {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      console.log('here')
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            console.log('here')
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
  },
  methods: {}
}
</script>