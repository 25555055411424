import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './components/Home.vue'

import Concepts from './components/getting_started/Concepts.vue'
import AccountCreation from './components/getting_started/AccountCreation.vue'
import Configuration from './components/getting_started/Configuration.vue'

import UserManagement from './components/administration/UserManagement.vue'
import Roles from './components/administration/Roles.vue'
import AccessGroups from './components/administration/AccessGroups.vue'

import Estimates from './components/Estimates.vue'
import Budgets from './components/Budgets.vue'
import Invoices from './components/Invoices.vue'
import Timesheets from './components/Timesheets.vue'
import Catalog from './components/Catalog.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/getting_started/concepts',
        name: 'Concepts',
        component: Concepts,
        props: true,
        children: []
      },
      {
        path: '/getting_started/account_creation',
        name: 'Account Creation',
        component: AccountCreation,
        props: true,
        children: []
      },
      {
        path: '/getting_started/configuration',
        name: 'Configuration',
        component: Configuration,
        props: true,
        children: []
      },
      {
        path: '/administration/user_management',
        name: 'User Management',
        component: UserManagement,
        props: true,
        children: []
      },
      {
        path: '/administration/roles',
        name: 'Roles',
        component: Roles,
        props: true,
        children: []
      },
      {
        path: '/administration/access_groups',
        name: 'Access Groups',
        component: AccessGroups,
        props: true,
        children: []
      },
      {
        path: '/timesheets',
        name: 'Timesheets',
        component: Timesheets,
        props: true,
        children: []
      },
      {
        path: '/budgets',
        name: 'Budgets',
        component: Budgets,
        props: true,
        children: []
      },
      {
        path: '/invoices',
        name: 'Invoices',
        component: Invoices,
        props: true,
        children: []
      },
      {
        path: '/estimates',
        name: 'Estimates',
        component: Estimates,
        props: true,
        children: []
      },
      {
        path: '/catalog',
        name: 'Catalog',
        component: Catalog,
        props: true,
        children: []
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: routes
})

export default router