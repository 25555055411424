import '@fortawesome/fontawesome-pro/css/all.css'
import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import router from './router'

import 'vuetify/dist/vuetify.min.css'

Vue.config.productionTip = false

const vuetifyOpts = {
  icons: {
    values: {},
    iconfont: 'fa'
  }
}
Vue.use(Vuetify)

new Vue({
  vuetify: new Vuetify(vuetifyOpts),
  router,
  render: h => h(App),
}).$mount('#app')
