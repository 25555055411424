<template>
  <v-app id="pricetable-app">
    <!-- <v-app-bar app clipped-left dark dense color="blue-grey darken-3" flat>
      Pricetable Documentation
    </v-app-bar> -->
    
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-main>
    <v-footer height="auto" style="text-align:center;border-top:1px solid lightgrey;display:block">
      <div style="text-align:center;display:block" class="text--disabled">
          &copy;2021 — PriceTable<small></small>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
.list-activator-icon .v-list-item__icon {
  margin-right: 16px !important;
}
</style>
