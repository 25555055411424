<template>
  <div>
    <v-navigation-drawer
      v-model="navDrawer"
      app
      clipped
      class="side-navbar"
    >
      <div class="pa-3 font-weight-bold">PriceTable Documentation</div>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-group
          prepend-icon="fal fa-tachometer-alt"
          group="getting_started"
          append-icon=''
          class="list-activator-icon">
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-title>
                Getting Started
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item :to="{ name: 'Account Creation' }">
            <v-list-item-action>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Creating Your Workspace 
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'Configuration' }">
            <v-list-item-action>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Configuring Your Workspace 
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'Concepts' }">
            <v-list-item-action>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Concepts 
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          prepend-icon="far fa-user-shield"
          group="administration"
          append-icon=''
          class="list-activator-icon">
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-title>
                Administration
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item :to="{ name: 'User Management' }">
            <v-list-item-action>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                User Management 
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'Roles' }">
            <v-list-item-action>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Roles
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'Access Groups' }">
            <v-list-item-action>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Access Groups 
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item :to="{ name: 'Timesheets' }" disabled>
          <v-list-item-action>
            <v-icon>fal fa-clock</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Time Tracking and Reporting 
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Estimates' }" disabled>
          <v-list-item-action>
            <v-icon>fal fa-handshake</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Estimates 
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Budgets' }" disabled>
          <v-list-item-action>
            <v-icon>fal fa-receipt</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Budgets 
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Invoices' }" disabled>
          <v-list-item-action>
            <v-icon>fal fa-file-invoice-dollar</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Invoices 
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Catalog' }" disabled>
          <v-list-item-action>
            <v-icon>fal fa-list</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Catalog Management 
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-fade-transition mode="out-in">
      <v-container fluid class="pa-4">
        <router-view></router-view>
      </v-container>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      navDrawer: true
    }
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
